<template>
  <div class="block__subtitle mb-40">{{ title }}</div>
  <VProfile
    v-if="!isEdit && !isChangePassword"
    class="mb-60"
    :class="classNameProfile"
    :avatar="getAvatar"
    show-buttons
    @onEdit="goEdit"
    @onChangePassword="changeIsChangePassword"
  >
    <VProfileInfo />
  </VProfile>

  <VProfile
    v-if="!isEdit && isChangePassword"
    class="mb-60"
    :class="classNameProfile"
    show-buttons
    :avatar="getAvatar"
    @onEdit="goEdit"
  >
    <VChangePassword
      @cancel="changeIsChangePassword"
    />
  </VProfile>
  <div v-if="!isEdit" class="block__tabs mb-40">
    <VTabs is-small :items="organizerProfile"/>
  </div>
  <router-view/>
</template>

<script>
import VProfile from '@/components/profile/VProfile'
import VProfileInfo from '@/components/profile/VProfileInfo'
import VChangePassword from '@/components/profile/VChangePassword'
import VTabs from '@/components/views/Tabs/VTabs'
import { organizerProfile } from '@/utils/tabs'
import { computed, ref } from 'vue'
import router from '@/router'
import { useStore } from 'vuex'

export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup () {
    const store = useStore()
    const isChangePassword = ref(false)

    const goEdit = () => {
      router.push({ name: 'organizer-profile-edit', params: { id: store.getters['auth/getUser'].id } })
    }

    const isEdit = computed(() => {
      return router.currentRoute.value.name.includes('edit')
    })

    const isApplicant = computed(() => {
      return store.getters['auth/getUser'].roles?.[0]?.name === 'user' && !isEdit.value
    })

    const classNameProfile = computed(() => {
      return isApplicant.value ? 'mb-30' : 'mb-60'
    })

    const getAvatar = computed(() => {
      return store.getters['auth/getUser']?.avatar?.[0][0].url
    })

    const changeIsChangePassword = () => {
      isChangePassword.value = !isChangePassword.value
    }

    return {
      classNameProfile,
      getAvatar,
      goEdit,
      isEdit,
      organizerProfile,
      changeIsChangePassword,
      isChangePassword
    }
  },
  components: {
    VProfile,
    VProfileInfo,
    VTabs,
    VChangePassword
  }
}
</script>

<style>
</style>
